<template>
    <router-view />
</template>

<script>
import create_store_step from "pages/create_store/wizard/create";

export default {
    name: "CreateStoreIndex",
    data() {
        return {
            sidebarGroup: {
                title: "Create Store",
                children: [

                ]
            },
        };
    },

    components: {
        create_store_step
    }
};
</script>

